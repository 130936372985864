<!-- 添加电子围栏弹框 -->
<template>
  <div ref="parent">
    <a-modal
      centered
      :width="469"
      :visible="visible"
      title="添加电子围栏"
      :zIndex="999"
      :maskClosable="true"
      @cancel="handleCancel"
      :getContainer="() => $refs.parent"
    >
      <a-form ref="formRef" :model="formData" :rules="rules" layout="vertical">
        <a-form-item label="围栏名称" name="railName" class="fence-form-item">
          <a-input v-model:value="formData.railName" :maxlength="20" class="fence-input" placeholder="请输入围栏名称" />
        </a-form-item>
        <a-form-item
          label="围栏中心点"
          name="railAddress"
          :class="formData.railAddress.length ? 'fence-form-item err-none' : 'fence-form-item'"
        >
          <div @click="gdMapVisible = true" class="fence-input fence-center">
            <span v-if="formData.railAddress.length" style="color:#333">{{ formData.railAddress }}</span>
            <span v-else style="color:#bfbfbf">请选择围栏中心点</span>
          </div>
        </a-form-item>
        <a-form-item label="围栏半径" name="railRadius" class="fence-form-item fence-railRadius">
          <a-input
            v-model:value="formData.railRadius"
            :maxlength="7"
            class="fence-input"
            @change="formData.railRadius = formData.railRadius.replace(/\D/g, '')"
            style="width:87px;text-align: center;"
          />
          <span class="fence-unit">米</span>
          <span class="fence-exp">（最小半径200米）</span>
        </a-form-item>
      </a-form>
      <template #footer>
        <a-button @click="handleCancel" class="MyCancel">取消</a-button>
        <a-button class="comfirm" @click="handleOk" :loading="loading">确定</a-button>
      </template>
    </a-modal>
    <GdMap
      v-model:gdMapVisible="gdMapVisible"
      :gdMapTitle="gdMapTitle"
      :gdMapPlaceholder="gdMapPlaceholder"
      @addressChange="addressChange"
      :selectAddress="selectAddress"
    />
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, ref, watch } from 'vue'
import '@/style/jsx-label-text.scss'
import WrapModal from '@/components/WrapModal'
import GdMap from '@/components/GdMap'
import { cmsNotice } from '@/utils/utils'

export default defineComponent({
  components: { WrapModal, GdMap },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    current: {
      type: Object,
      default: {}
    },
    actionType: {
      type: String,
      default: 'add'
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const { emit } = ctx
    const state = reactive({
      gdMapVisible: false,
      gdMapPlaceholder: '根据关键字搜索',
      gdMapTitle: '选择围栏中心点',
      selectAddress: {}
    })
    const formData = reactive({
      railName: '',
      railRadius: undefined,
      railAddress: '',
      lon: '',
      lat: ''
    })

    const formRef = ref()
    const rules = {
      railName: [
        { required: true, message: '请输入围栏名称', trigger: 'change' },
        {
          pattern: '^[\u9FA6-\u9FCB\u3400-\u4DB5\u4E00-\u9FA5_a-zA-Z0-9]+$',
          message: '围栏名称不能有空格,特殊字符',
          trigger: 'change'
        }
      ],
      railAddress: [{ required: true, message: '请选择围栏中心点', trigger: ['blur', 'change'] }],
      railRadius: [
        { required: true, message: '请输入围栏半径', trigger: 'change' },
        { pattern: '^1|2|3|4|5|6|7|8|9[0]*$', message: '请输入不以0开头的正整数', trigger: 'change' }
      ]
    }
    // 取消
    const handleCancel = () => {
      formRef.value.resetFields()
      emit('update:visible', false)
    }

    // 确定
    const handleOk = () => {
      if (formData.railRadius < 200) {
        cmsNotice('error', '围栏半径最少200米')
      } else {
        emit('update:loading', true)
        formRef.value
          .validate()
          .then(() => emit('modalSubmit', formData))
          .catch(() => emit('update:loading', false))
      }
    }

    // 填写表单
    const addressChange = value => {
      formData.railAddress = value.name
      formData.lon = value.lng
      formData.lat = value.lat
      state.selectAddress = value
      state.gdMapVisible = false
    }

    watch(
      () => props.visible,
      () => {
        if (props.actionType == 'add') {
          state.selectAddress = {}
          for (let key in formData) {
            if (key == 'railRadius') formData[key] = '200'
          }
        }
        if (!props.visible) {
          formData.railAddress = ''
          formData.lon = ''
          formData.lat = ''
          formData.railName = ''
          formData.railRadius = undefined
        }
      }
    )

    return {
      ...toRefs(state),
      formData,
      formRef,
      handleOk,
      handleCancel,
      rules,
      addressChange
    }
  }
})
</script>

<style lang="scss" scoped>
.fence-form-item {
  padding-bottom: 0;

  :deep(.ant-form-item-control-wrapper) {
    margin-left: 10px;
  }

  :deep(.has-error) {
    .fence-center {
      border-color: #ff4d4f;
    }
  }
}

.fence-railRadius {
  :deep(.ant-form-item-children) {
    width: 411px;
    display: inline-block;
    position: relative;
  }

  .fence-exp {
    position: absolute;
    top: -27px;
    left: 60px;
    color: #bfbfbf;
    font-size: 12px;
  }
}

.fence-input {
  border-radius: 2px;
  width: 411px;
}

.fence-center {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  font-size: 14px;
}

.err-none {
  :deep(.has-error) {
    margin-bottom: 20px;

    .fence-center {
      border-color: #d9d9d9;
    }
  }

  :deep(.ant-form-explain) {
    display: none;
  }
}

.fence-unit {
  margin-left: 10px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 32px;
  height: 32px;
  display: inline-block;
}

.comfirm {
  background: #c3161c;
  color: #fff;
  border: 1px solid transparent;
  margin-right: 0;

  &:hover {
    border-color: transparent;
  }
}
</style>
